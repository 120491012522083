import usLangDashboard from './Personal/Dashboard/us';
import usLangEmployee from './Personal/Employee/us';
import usLangPermission from './Personal/Permission/us';
import usLangRole from './Personal/Role/us';
import usLangShop from './Personal/Shop/us';
import usLangStaff from './Personal/Staff/us';
import usLangWorkTime from './Personal/WorkTime/us';
import usLangWorkOff from './Personal/WorkOff/us';
import usLangWorkConfig from './Personal/WorkConfig/us';
import usLangSalary from './Personal/Salary/us';
import usLangSell from './Personal/Sell/us';
import usLangSaler from './Personal/Saler/us';
import usLangContract from './Personal/Contract/us';
import usLangCustomer from './Personal/Customer/us';
import usLangProduct from './Personal/Product/us';
import usLangSale from './Personal/Sale/us';
import usLangOrder from './Personal/Order/us';

const topNav = {
  'sidebar.dropdown.account': 'Account',
  'sidebar.dropdown.change-password': 'Change password',
  'sidebar.dropdown.support': 'Support',
  'sidebar.dropdown.sign-out': 'Sign out',
  'topnav.dropdown.change-team.title': 'Team list',
  'topnav.dropdown.item.personal': 'Personal account',
  'topnav.noti.change-current-team.request': 'Shifting work space',
  'topnav.noti.change-current-team.failure': 'Moving work space failed',
};

const authPage = {
  'auth.login.helmet-title': 'Login',
};

const sidebar = {
  //
  'sidebar.dashboard': 'Dashboard',
  'sidebar.welcome': 'Welcome',
  //
  'sidebar.employee': 'Employee Manager',
  'sidebar.list-employees': 'List employees',
  'sidebar.add-employee': 'Add employee',
  //
  'sidebar.docs': 'Docs',

  //

  'sidebar.dropdown.bookVacation': 'Book vacation',
  'sidebar.dropdown.bookSchedule': 'Book schedule',
  'sidebar.warehouse': 'Warehouse',
  'sidebar.action-warehouse': 'Import/Export warehouse',
  'sidebar.discount': 'Discount',
  'sidebar.import': 'Import warehouse',
  'sidebar.export': 'Export warehouse',
  'sidebar.warehouse.manager': 'Manager warehouse',
  'sidebar.warehouse.meko': 'Inventory',
  'sidebar.warehouse.check': 'Check warehouse',

  //
  //
  'sidebar.footer-action.create': 'Create order',
  'sidebar.footer-action.list': 'List all orders',
  'sidebar.footer-action.dashboard': 'Dashboard',
  'sidebar.footer-action.revenue': 'Revenue',
  'sidebar.constants-dashboard': 'Dashboard',
  'sidebar.constants-order': 'Order',
  'sidebar.constants-create-order': 'Create order',
  'sidebar.constants-dispatcher-order': 'Dispatcher order',
  'sidebar.constants-list-order': 'List all orders',
  'sidebar.constants-order-tracked': 'Orders need to be tracked',
  'sidebar.constants-list-order-app': 'List order from app',
  'sidebar.constants-user': 'User',
  'sidebar.sale-page': 'Sale page',
  'sidebar.sell': 'Sell',
  'sidebar.product': 'Product',
  'sidebar.unit': 'Unit',
  'sidebar.category': 'Category',
  'sidebar.supplier': 'Supplier',
  'sidebar.constants-customer': 'Customer',
  'sidebar.constants-customer-app': 'Customer app',
  'sidebar.constants-employees': 'Employees',
  'sidebar.constants-salers': 'Saler',
  'sidebar.constants-customers': 'Customer',
  'sidebar.constants-role': 'Role',
  'sidebar.constants-permissions': 'Permission',
  'sidebar.constants-work-place': 'Work place',
  'sidebar.constants-timekeeping': 'Timekeeping',
  'sidebar.constants-business-schedule': 'Business schedule',
  'sidebar.constants-vacation-schedule': 'Vacation schedule',
  'sidebar.constants-config-check-in': 'Config check in',
  'sidebar.constants-admin': 'Admin',
  'sidebar.constants-notification': 'Notification',
  'sidebar.constants-shipping': 'Shipping',
  'sidebar.constants-report': 'Report',
  'sidebar.constants-revenue': 'Revenue',
  'sidebar.constants-inventory': 'Inventory',
  'sidebar.constants-sub-shipping': 'Shipping',
  'sidebar.constants-region-group': 'Region group',
  'sidebar.constants-place': 'Place',
  'sidebar.constants-product': 'Product',
  'sidebar.constants-list-product': 'List products',
  'sidebar.constants-list-product-types': 'List product types',
  'sidebar.constants-list-product-price': 'Product price',
  'sidebar.constants-manage-accumulation': 'Manage accumulation',
  'sidebar.constants-blog': 'Blog',
  'sidebar.constants-voucher': 'Voucher',
  'sidebar.constants-gift': 'Gift',
  'sidebar.constants-config': 'Config',
  'sidebar.constants-call-center': 'Call center',
  'sidebar.constants-label': 'Label',
  'sidebar.constants-source': 'Source',
  'sidebar.constants-feedback': 'Feedback',
  'sidebar.constants-data': 'Data',
  'sidebar.constants-log': 'Log',
  'sidebar.constants-customer-care': 'Customer need take care',
  'sidebar.constants-warehouse': 'Warehouse',
  'sidebar.constants-shop': 'Shop',
  'sidebar.constants-work-time': 'Work time',
  'sidebar.constants-work-off': 'Work off',
  'sidebar.constants-salary': 'Salary',
  'sidebar.list-import-export': 'List export and import',
  'sidebar.lookup-product': 'Lookup product',
  'sidebar.constants-payroll': 'Payroll',
  'sidebar.order-list': 'Order List',
  'sidebar.order-return': 'Return Order',
  'sidebar.table-group': 'Table area',
  'sidebar.table': 'List of desks',
};

const user = {
  'user.currentPassword': 'Current password',
  'user.newPassword': 'New password',
  'user.confirmNewPassword': 'Confirm password',
  'user.changePasswordButton': 'Change password',
  'user.changePasswordSuccess': 'Change password success',
  'user.currentPassword.notValid': 'Current password is incorrect',
  'user.information': 'Information',
  'user.schedule': 'Schedule',
  'user.work-tracking': 'Action',
  'user.schedule.date-from': 'Start date',
  'user.schedule.time-from': 'Start time',
  'user.schedule.date-to': 'End date',
  'user.schedule.time-to': 'End time',
  'user.schedule-info': 'Schedule',
  'user.vacation': 'Calendar vacation',
  'user.work-tracking.late': 'Late',
  'user.work-tracking.ok': 'On time',
  'user.work-tracking.early': 'Early',
  'user.work-tracking.notMatch': 'Not match place',
  'user.work-tracking.notMatch.late': 'Not match place and late',
  'user.timekeeping': 'Timekeeping',
  'user.workTracking': 'Log time work tracking',
  'user.profile.account-disable': 'Inactive',
  'user.profile.account-enable': 'Activate',
  'user.config': 'Config user',
  'user.config.manager': 'Manager config user',
  'user.config.accounting': 'Accounting',
  //
  // Change password
  'user.user-change.change-password': 'Change password',
  'user.user-change.current-password': 'Current Password',
  'user.user-change.new-password': 'New Password',
  'user.user-change.confirm-password': 'Confirm Password',
  'user.user-change.apply': 'Apply',
  'user.user-change.cancel': 'Cancel',
  'user.user-change.success': 'Change password success',
  'user.user-change.must-characters': 'Value must be longer than 4 characters',
  'user.user-change.lower-character': 'Value a least one character lower case letter',
  'user.user-change.upper-character': 'Value a least one character upper case letter',
  'user.user-change.one-character-number': 'Value a least one character number',
  'user.user-change.one-special-character': 'Value a least one special character',
  'user.user-checkout.confirm-checkout': 'Confirm checkout?',
  'user.user-checkout.confirm': 'Confirm',

  // User profile
  'user.user-profile.profile': 'Profile',
  'user.user-profile.update-avatar': 'Update avatar',
  'user.user-profile.full-name': 'Full name',
  'user.user-profile.enter-full-name': 'Please enter the full name',
  'user.user-profile.check-characters': 'At least 4 characters',
  'user.user-profile.email-required': 'Email is required',
  'user.user-profile.email-not-correct': 'Email is not correctly formatted.',
  'user.user-profile.phone-not-valid': 'Phone number not valid',
  'user.user-profile.phone-check-characters': 'Phone number must have 10 characters',
  'user.user-profile.phone-check-characters-again': 'Phone number must have 10 characters',
  'user.user-profile.status-account': 'Status of account:',
  'user.user-profile.start-date': 'Start date:',
  'user.user-profile.status-profile': 'Status of profile:',
  'user.user-profile.position': 'Position:',
  'user.user-profile.update-failed': 'Update information is failed',
  'user.user-profile.update-information': 'Update personal information',
  'user.user-profile.save': 'Save',
  'user.user-profile.back': 'Back',
  'user.user-contact.contact-information': 'The contact information',
  'user.user-contact.email-required': 'Email is required',
  'user.user-contact.email-not-correct': 'Email is not correctly formatted.',
  'user.user-contact.phone-number': 'Phone number:',
  'user.user-contact.information-not-provided': 'Information has not been provided',
  'user.user-contact.phone-not-valid': 'Phone number not valid',
  'user.user-contact.phone-must-characters': 'Phone number must have 10 characters',
  'user.user-contact.phone-must-characters-again': 'Phone number must have 10 characters',
  'user.user-contact.zalo-information-not-provided': 'Information has not been provided',
  'user.user-contact.facebook-information-not-provided': 'Information has not been provided',
  'user.user-profile.enter-current-password': 'Please enter the current password.',
  'user.user-profile.enter-new-password': 'Please enter a new password.',
  'user.user-profile.password-must-characters': 'The password has at least 5 characters!',
  'user.user-profile.password-one-typical-character': 'The password has at least one typical character',
  'user.user-profile.password-one-floral-character': 'The password contains at least one floral letter',
  'user.user-profile.password-one-number': 'The password has at least one number',
  'user.user-profile.password-must-special-character': 'The password has at least one special character',
  'user.user-profile.password-confirm-required': 'Confirm password is required',
  'user.user-profile.password-must-match-new-password': ' The confirmation password must match the new password entered',

  // user-statusVacation
  'user.user-profile.waiting': 'Waiting',
  'user.user-profile.denied': 'Denied',
  'user.user-profile.paid-leave': 'Paid leave',
  'user.user-profile.unpaid-leave': 'Unpaid leave',

  // user profile
  'user.user-profile.profile-title': 'Profile',
  'user.user-profile.personal-information': 'The personal information',
  'user.user-profile.work-position': 'Position:',
  'user.user-profile.work-information-not-provided': 'Information has not been provided',
  'user.user-profile.work-department': 'Department:',
  'user.user-profile.work-position-not-information': 'Information has not been provided',
  'user.user-profile.work-start-date': 'Start date:',
  'user.user-profile.work-start-date-not-information': 'Information has not been provided',
  'user.user-profile.work-status-profile': 'Status of profile:',
  'user.user-profile.work-status-profile-not-information': 'Information has not been provided',
  'user.user-profile.work-status-account': 'Status of account:',

  'user.user-profile.work-status-null': 'Information has not been provided',
  'user.user-profile.work-status-working': 'Working',
  'user.user-profile.work-status-missing': 'Missing',
};

const table = {
  'table.image.list-products-name': 'Name',
  'table.image.list-products-type': 'Type',
  'table.image.list-products-empty': '(Empty)',
  'table.image.list-products-unit': 'Unit',
  'table.image.list-product-types-name': 'Name',
  'table.image.list-product-types-description': 'Description',
  'table.image.remaining-name': 'Name',
  'table.image.remaining-phone': 'Phone',
  'table.thumb.list-products-name': 'Name',
  'table.thumb.list-product-types-name': 'Name',
  'table.thumb.list-product-types-description': 'Description',
  'table.thumb.remaining-name': 'Name',
  'table.thumb.remaining-phone': 'Phone number',
  'table.thumb.type': 'Type',
  'table.thumb.empty': '(Empty)',
  'table.thumb.unit': 'Unit',
  'table.image.qua': 'Qua',
  'table.image.pack': 'Pack',
  'table.thumb.qua': 'Qua',
  'table.thumb.pack': 'Pack',

};

const common = {
  'common.list': 'List',
  'common.config': 'Config',
  'common.province': 'Province',
  'common.district': 'District',
  'common.ward': 'Ward',
  'common.week': 'Week',
  'common.name_customer': 'Customer name',
  'common.salers': 'Salers',
  'common.image': 'Image',
  'common.accumulation': 'Accumulation',
  'common.pay.salary': 'Pay salary',
  'common.extend': 'Extend',
  'common.payoff': 'Remunerate/punish',
  'common.salary': 'Salary',
  'common.from-date': 'From date',
  'common.to-date': 'To date',
  'common.reason.required': 'Required reason',
  'common.user-confirm': 'User confirm',
  'common.staff': 'Staff',
  'common.staffBackup': 'Staff backup',
  'common.timeFrom': 'Time from',
  'common.timeTo': 'Time to',
  'common.password': 'Password',
  'common.gender': 'Gender',
  'common.birthday': 'Birthday',
  'common.avatar': 'Avatar',
  'common.address': 'Address',
  'common.username': 'Username',
  'common.phone': 'Phone',
  'common.home': 'Home',
  'common.notice': 'Notice',
  'common.createdAt': 'Created at',
  'common.creator': 'Creator',
  'common.updatedAt': 'Updated at',
  'common.status': 'Status',
  'common.loading': 'Loading..',
  'common.disable': 'Disable',
  'common.enable': 'Enable',
  'common.select': 'Select',
  'common.selected': 'Selected',
  'common.bigSizeImage': 'Too big, please choose another photo',
  'common.note': 'Note',
  'common.description': 'Description',
  'common.date': 'Date',
  'common.selectAll': 'Select All',
  'common.unSelectAll': 'Unselect All',
  'common.accounting': 'Accounting Voucher',
  'common.hours': 'Hours',
  'common.employee.name': 'Employee name',
  'common.type': 'Type',
  'common.mon': 'Mon',
  'common.tue': 'Tue',
  'common.wed': 'Wed',
  'common.thur': 'Thur',
  'common.fri': 'Fri',
  'common.sat': 'Sat',
  'common.sun': 'Sun',
  'common.date.total': 'Total day',
  'common.name': 'Name',
  'common.total': 'Total',
  'common.filter': 'Filter',
  'common.filter.member': 'Member',
  'common.reason': 'Reason',
  'common.number': 'Number',
  'common.money': 'Total',
  'common.place-export': 'Place out',
  'common.place-export-receive': 'Place receive',
  'common.code': 'Code',
  'common.by': 'By',
  'common.from': 'from',
  'common.to': 'to',
  'common.shipping': 'Shipping',
  'common.update.contemporary.success': 'Update contemporary success',
  'common.update.contemporary.failure': 'Update contemporary failure',
  'common.title': 'Title',
  'common.month': 'Month',
  'common.year': 'Year',
  'common.on': 'On',
  'common.off': 'Off',
  'common.search': 'Search',
  'common.city': 'City',
  'common.transport': 'Transport',
  'common.transport.confirm': 'Confirm',
  'common.transport.confirmSure': 'You will definitely',
  'common.copy.success': 'Copy success',
  'common.schedule': 'Schedule',
  'common.schedule.statistic': 'Schedule statistic',
  'common.object': 'Object',
  'common.time': 'Time',
  'common.rewarded': 'Rewarded',
  'common.honored': 'Honored',
  'common.active': 'Active',
  'common.unactive': 'Not activated',
  'common.update.place': 'Please update place customer',
  'common.discount.code': 'Code discount',
  'common.info.voucher': 'Voucher information',
  'common.endow': 'Endow',
  'common.information.empty': 'Information is missing',
  'common.accept': 'Accept',
  'common.refuse': 'Refuse',
  'common.pending': 'Pending',
  'common.payment': 'Payment',
  'common.content': 'Content',
  'common.score': 'Score',
  'common.day.online': 'Online',
  'common.day.offline': 'Offline',
  'common.turnOn': 'Turn on',
  'common.turnOff': 'Turn off',
  'common.sale': 'Sale',
  'common.method': 'Method',
  'common.fee': 'Fee',
  'common.ship': 'Ship',
  'common.price': 'Price',
  'common.save.fee': 'Save fee',
  'common.box': 'Box',
  'common.cash': 'Cash',
  'common.totalCash': 'Total cash',
  'common.canceled': 'Canceled',
  'common.history': 'History',
  'common.none': 'None',
  'common.accepted': 'Accepted',
  'common.denied': 'Denied',
  //
  //
  'common.dashboard': 'Dashboard',
  'common.profile': 'Profile',
  'common.list.empty': 'Empty list',
  'common.check-in': 'Check in',
  'common.logout': 'Log out',
  'common.account': 'Account',
  'common.change-password': 'Change Password',
  'common.sign-out': 'Sign out',
  'common.dashboard-get-menu': 'Dashboard',
  'common.loudspeaker': 'Turn on/off loudspeaker',
  'common.Checkout': 'Check out',
  'common.language-month': 'es',
  'common.language-year': 'es',
  'common.update': 'Update',
  'common.number.bag': 'Number bag',
  'common.history.update': 'History update',
  'common.egg': 'Egg',
  'common.flour': 'Flour',
  'common.million': 'Million',
  'common.view-all': 'View all',
  'common.news': 'News',
  'common.news.nothing': 'There is no bulletin board',
  'common.supplier': 'Supplier',
  'common.content-hi': 'Good',
  'common.orders': 'Orders',
  'common.system': 'System',
};
const sort = {
  'sort.asc': 'Ascending',
  'sort.desc': 'Decrease',
  'sort.more': 'More',
  'sort.sortBy': 'Sort By',
  'sort.orderBy': 'Order By',
  'sort.search': 'Search...',
  'sort.employee': 'Order By Employee',
  'sort.month': 'Order by month',
  'sort.orderByEmployee': 'Order by employee',
  'sort.date': 'Date',
  'sort.status': 'Status',
  'sort.staff': 'Staff',
  'sort.warehouse': 'Warehouse',
  'sort.place': 'Branch',
  'sort.method': 'Method',
};
const action = {
  'action.name': 'Action',
  'action.multi-excution': 'Multi Excution',
};

const accounting = {
  'accounting.misa.create': 'Create Accounting Voucher Misa',
  'accounting.misa.created': 'Misa Voucher Created',
  'accounting.misa.canceled': 'Misa Voucher Canceled',
  'accounting.misa.include-import': 'Includes Misa Import Voucher',
};

const dateFormat = {
  'date.today': 'Today',
  'date.yesterday': 'Yesterday',
  'date.this-week': 'This week',
  'date.last-week': 'Last week',
  'date.this-month': 'This month',
  'date.last-month': 'Last month',
  'date.from-today': 'days up to today',
};

const value = {
  'value.empty': 'Empty',
  'value.empty-list': 'List is empty',
  'value.view': 'View',
  'value.total': 'Total',
  'value.error.less.zero': 'Number can not less than 0',
  'value.error.number': 'Please enter number',
  'value.phone.invalid': 'Phone number is not valid',
};

const btn = {
  'btn.confirm': 'Confirm',
  'btn.update': 'Update',
  'btn.close': 'Close',
  'btn.back': 'Back',
  'btn.yes': 'Yes',
  'btn.cancel': 'Cancel',
  'btn.save': 'Save',
  'btn.finish': 'Finish',
  'btn.get-price': 'Get price',
  'btn.transport-order': 'Transport Order',
  'btn.save-config': 'Save Config',
  'btn.claimPermission': 'Claim Permission',
  'btn.accept': 'Accept',
  'btn.add': 'Add',
};

const personal = {
  'personal.check-in-success': 'Check in success',
  'personal.check-in-notice': 'Notice',
  'personal.checkout-success': 'Checkout success',
  'personal.checkout-notice': 'Notice',
  'personal.current-password': 'Please enter your current password',
  'personal.new-password': 'Please enter a new password',
  'personal.minimum-characters': 'Password must be at least 5 characters!',
  'personal.lowercase-characters': 'Password has at least 1 lowercase character!',
  'personal.uppercase-characters': 'Password has at least 1 uppercase character!',
  'personal.numeric-characters': 'Password has at least 1 numeric character!',
  'personal.special-characters': 'Password has at least 1 special character!',
  'personal.confirm-new-password': 'Please enter confirm new password',
  'personal.match-new-password': 'Password must match the new password entered',
  'personal.reset-password-successfully': 'Reset password successfully',
  'personal.reset-password-first': 'Reset password the first time you log in',
  'personal.reset-password-failed': 'Reset password failed',
  'personal.reset-current-password': 'Current password',
  'personal.reset-new-password': 'New password',
  'personal.check-characters': '5-20 characters.',
  'personal.check-lowercase-letter': 'At least one lowercase letter.',
  'personal.check-uppercase-letter': 'At least one uppercase letter.',
  'personal.check-numeric-character': 'At least one numeric character.',
  'personal.check-special-character': 'At least one special character.',
  'personal.check-confirm-new-password': 'Confirm new password',
  'personal.components-order': 'Orders',
  'personal.components-default': 'System',
  'personal.create-order-header': 'Create order',
  'personal.personal.create-order-footer': 'Create order',
  'personal.confirm-add-order': 'Confirm add order',
  'personal.confirm-definitely': 'You will definitely add this order?',
  'personal.fill-information': 'Please fill in the recipient information',
  'personal.fill-phone-number': 'Phone number',
  'personal.recipient-name': 'Recipient\'s name',
  'personal.address': 'Address',
  'personal.details': 'Details',
  'personal.confirm-product-order': 'Confirm to remove the product from the order',
  'personal.confirm-definitely-product-order': 'You definitely want to remove this product from your order?',
  'personal.product-name': 'Enter product name',
  'personal.fee': 'Fee',
  'personal.confirm-cancel-order': 'Confirm cancel order',
  'personal.confirm-definitely-cancel-order': 'You will definitely cancel this order?',

  // Personal Infomation
  'personal.male': 'Male',
  'personal.female': 'Female',
  'personal.unspecified': 'I do not want to be public',
  'personal.married': 'Married',
  'personal.single': 'Single',
  'personal.personal-information': 'Personal information',
  'personal.personal-birthday': 'Birthday:',
  'personal.information-not-provided': 'Information has not been provided:',
  'personal.gender': 'Gender:',
  'personal.marital-status': 'Marital status:',
  'personal.personal-id': 'Personal ID:',
  'personal.personal-id-date': 'Personal ID date:',
  'personal.personal-id-place': 'Personal ID place:',
  'personal.personal-address': 'Address:',
  'personal.personal-address-not-information': 'Information has not been provided',
  'personal.personal-label-not-information': 'Information has not been provided',
  'personal.personal-telephone-not-information': 'Information has not been provided',

  'personal.gender-information-not-provided': 'Information has not been provided',
  'personal.gender-male': 'Male',
  'personal.gender-female': 'Female',
  'personal.gender-no-share': 'Don\'t share',

  'personal.marital-status-not-information': 'Information has not been provided',
  'personal.marital-status-single': 'Single',
  'personal.marital-status-married': 'Married',
  'personal.personal-id-not-information': 'Information has not been provided',
  'personal.personal-id-date-not-information': 'Information has not been provided',
  'personal.personal-id-place-not-information': 'Information has not been provided',

};

const login = {
  'login.capcha-required': 'Captcha is required',
  'login.capcha-incorrect': 'Captcha is incorrect',
  'login.password-required': 'Password is required',
  'login.password-must-be-characters': 'Password must be longer than 4 characters',
  'login.email-required': 'Email is required',
  'login.invalid-email': 'Invalid email address',

};

const notfound = {
  'notfound.helmet.title': 'Not found page',
  'notfound.error-occurred': 'Ooops... looks like an error occurred!',
  'notfound.error-code': 'Error code',
  'notfound.back-home': 'Go back home',

};
const usLangLib = {
  ...btn,
  ...value,
  ...action,
  ...accounting,
  ...sort,
  ...topNav,
  ...sidebar,
  ...user,
  ...common,
  ...notfound,
  ...login,
  ...table,
  ...personal,
  ...authPage,
  ...dateFormat,
  ...usLangDashboard,
  ...usLangEmployee,
  ...usLangPermission,
  ...usLangRole,
  ...usLangShop,
  ...usLangStaff,
  ...usLangWorkTime,
  ...usLangWorkOff,
  ...usLangWorkConfig,
  ...usLangSalary,
  ...usLangSell,
  ...usLangSaler,
  ...usLangContract,
  ...usLangCustomer,
  ...usLangProduct,
  ...usLangSale,
  ...usLangOrder,
};

export default usLangLib;
