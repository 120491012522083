const shop = {
  'shop.name': 'Tên cửa hàng',
  'shop.create': 'Thêm cửa hàng',
  'shop.list': 'Danh sách cửa hàng',
  'shop.edit': 'Cập nhật cửa hàng',
  'shop.trial': 'Dùng thử',
  'shop.trial.7': '7 ngày',
  'shop.trial.15': '15 ngày',
  'shop.delete': 'Xóa cửa hàng',
  'shop.manage': 'Quản lý cửa hàng',
  'shop.create.confirm': 'Xác nhận tạo cửa hàng',
  'shop.create.confirmSure': 'Bạn chắc chắn tạo cửa hàng?',
  'shop.edit.confirm': 'Xác nhận cập nhật cửa hàng',
  'shop.edit.confirmSure': 'Bạn chắc chắn cập nhật cửa hàng?',
  'shop.create.success': 'Tạo cửa hàng thành công',
  'shop.update.success': 'Cập nhật cửa hàng thành công',
  'shop.resetPassword.success': 'Khôi phục mật khẩu cửa hàng thành công',
  'shop.confirm.resetPassword': 'Xác nhận đặt lại mật khẩu cửa hàng này?',
  'shop.detail': 'Chi tiết cửa hàng',
  'shop.username.required': 'Vui lòng nhập tên đăng nhập',
  'shop.shop_name.required': 'Vui lòng nhập tên cửa hàng',
  'shop.name_customer.required': 'Vui lòng nhập tên khách hàng',
  'shop.phone.required': 'Vui lòng nhập số điện thoại cửa hàng',
  'shop.password.required': 'Vui lòng nhập mật khẩu',
  'shop.type.label': 'Loại dịch vụ',
  'shop.type.value.pharmacy': 'Nhà thuốc',
  'shop.type.value.table': 'Đặt bàn',
  'shop.type.value.order': 'Khác',
  'shop.quantity.label': 'Có quản lý số lượng kho',
  'shop.auto.label': 'Tự động tạo phiếu xuất kho',
};

const config = {
  'shop-config.save': 'Lưu cấu hình',
  'shop-config.save.success': 'Lưu cấu hình thành công',
  'shop-config.save.confirm': 'Xác nhận lưu cấu hình',
  'shop-config.save.confirmSure': 'Bạn chắc chắn lưu cấu hình?',
  'shop-config.hetmet': 'Cấu hình cửa hàng',
  'shop-config.breadcrumb-title': 'Cấu hình cửa hàng',
  'shop-config.breadcrumb-parent': 'Cấu hình cửa hàng',
  // bank
  'shop-config.bank': 'Cấu hình chuyển khoản',
  'shop-config.bank.name': 'Tên tài khoản',
  'shop-config.bank.name.error-required': 'Tên tài khoản là bắt buộc',
  'shop-config.bank.name.placeholder': 'Vui lòng nhập tên tài khoản',
  'shop-config.bank.account': 'Số tài khoản',
  'shop-config.bank.account.error-required': 'Số tài khoản là bắt buộc',
  'shop-config.bank.account.placeholder': 'Vui lòng nhập số tài khoản',
  'shop-config.bank.nameBank': 'Tên ngân hàng',
  'shop-config.bank.nameBank.error-required': 'Tên ngân hàng là bắt buộc',
  'shop-config.bank.nameBank.placeholder': 'Vui lòng nhập tên ngân hàng',
  // bill
  'shop-config.bill': 'Cấu hình hóa đơn',
  'shop-config.bill.nameShop': 'Tên cửa hàng',
  'shop-config.bill.nameShop.placeholder': 'Nhập tên cửa hàng',
  'shop-config.bill.phoneShop': 'Số điện thoại cửa hàng',
  'shop-config.bill.phoneShop.placeholder': 'Nhập số điện thoại cửa hàng',
  'shop-config.bill.addressShop': 'Địa chỉ cửa hàng',
  'shop-config.bill.addressShop.placeholder': 'Nhập địa chỉ cửa hàng',
  'shop-config.bill.footer': 'Thông tin dưới hóa đơn',
  'shop-config.bill.footer.placeholder': 'Nhập thông tin dưới hóa đơn',

  // product
  'shop-config.product': 'Cấu hình sản phẩm',
  'shop-config.product.expiry': 'Số ngày sắp hết hạn',
  'shop-config.product.expiry.placeholder': 'Nhập số ngày sắp hết hạn',
  'shop-config.product.number': 'Số lượng sản phẩm sắp hết hàng',
  'shop-config.product.number.placeholder': 'Nhập số lượng sản phẩm sắp hết hàng',
};

const vnLangShop = {
  ...shop,
  ...config,
};

export default vnLangShop;
