import vnLangUnit from './Unit/vn';
import vnLangCategory from './Category/vn';
import vnLangSupplier from './Supplier/vn';
import vnLangWarehouse from './Warehouse/vn';
import vnLangActionWarehouse from './ActionWarehouse/vn';
import vnLangDiscount from './Discount/vn';
import vnLangProductLookup from './SearchProduct/vn';
import vnLangTableGroup from './TableGroup/vn';
import vnLangTable from './Table/vn';

const vnLangSell = {
  ...vnLangUnit,
  ...vnLangCategory,
  ...vnLangSupplier,
  ...vnLangWarehouse,
  ...vnLangActionWarehouse,
  ...vnLangDiscount,
  ...vnLangProductLookup,
  ...vnLangTableGroup,
  ...vnLangTable,
};

export default vnLangSell;
