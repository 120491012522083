const vnLangOrder = {
  'list-orders-all.helmet': 'Tất cả đơn hàng',
  'list-orders-all.breadcrumb.title': 'Danh sách đơn hàng',
  'list-orders-all.breadcrumb.parent': 'Quản lý đơn hàng',
  'list-orders-all.col.date': 'Ngày lên đơn',
  'list-orders-all.col.code': 'Mã đơn hàng',
  'list-orders-all.col.customer': 'Khách hàng',
  'list-orders-all.col.products': 'Sản phẩm',
  'list-orders-all.col.products.code': 'Mã SP',
  'list-orders-all.col.products.barcode': 'Mã vạch',
  'list-orders-all.col.products.name': 'Tên SP',
  'list-orders-all.col.products.number': 'Số lượng',
  'list-orders-all.col.payment': 'Thanh toán',
  'list-orders-all.col.payment.cash': 'Tiền mặt',
  'list-orders-all.col.payment.bank': 'Chuyển khoản',
  'list-orders-all.modal.detail.title.customer': 'Thông tin khách hàng',
  'list-orders-all.modal.detail.title.order': 'Thông tin đơn hàng',
  'list-orders-all.modal.name.label': 'Tên khách hàng',
  'list-orders-all.modal.phone.label': 'Số điện thoại',
  'list-orders-all.modal.address.label': 'Địa chỉ',
  'list-orders-all.modal.order-code.label': 'Mã đơn',
  'list-orders-all.modal.order-status.label': 'Hình thức thanh toán',
  'list-orders-all.modal.created.label': 'Ngày lên đơn',
  'list-orders-all.modal.note.label': 'Ghi chú đơn hàng',
  'list-orders-all.modal.items.label': 'Danh sách sản phẩm',
  'list-orders-all.modal.items.stt.col': 'STT',
  'list-orders-all.modal.items.info.col': 'Thông tin sản phẩm',
  'list-orders-all.modal.items.name.col': 'Tên SP',
  'list-orders-all.modal.items.code.col': 'Mã SP',
  'list-orders-all.modal.items.barcode.col': 'Mã vạch',
  'list-orders-all.modal.items.unit.col': 'Đơn vị tính',
  'list-orders-all.modal.items.source.col': 'Xuất xứ',
  'list-orders-all.modal.items.desc.col': 'Ghi chú',
  'list-orders-all.modal.items.number.col': 'Số lượng',
  'list-orders-all.modal.items.number.quantity.col': 'Đặt',
  'list-orders-all.modal.items.number.return.col': 'Trả',
  'list-orders-all.modal.items.price.col': 'Đơn giá',
  'list-orders-all.modal.items.discount.col': 'Giảm giá',
  'list-orders-all.modal.items.total.col': 'Thành tiền',
  'list-orders-all.modal.discount.label': 'Giảm giá',
  'list-orders-all.modal.total.label': 'Tổng cộng',
  'list-orders-all.title-empty': '(Chưa có dữ liệu)',
  'list-orders-all.note.copy': 'Copy thông tin khách hàng thành công',
  'list-orders-all.btn.detail': 'Chi tiết đơn hàng',
  'list-orders-all.btn.return': 'Trả hàng',
  'list-orders-all.modal.header.detail.title': 'Chi tiết thông tin đơn hàng',
  'list-orders-all.modal.header.return.title': 'Trả hàng',
  'list-orders-all.note.create-order-return.success': 'Trả hàng thành công!',
  'list-orders-all.note.create-order-return.failure': 'Trả hàng không thành công! Vui lòng thử lại sau.',
  'list-orders-all.guest': 'Khách vãng lai',
};

export default vnLangOrder;
