// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllOrdersListRequest = createAction('GET_ALL_ORDERS_LIST_REQUEST');
export const getAllOrdersListSuccess = createAction('GET_ALL_ORDERS_LIST_SUCCESS');
export const getAllOrdersListFailure = createAction('GET_ALL_ORDERS_LIST_FAILURE');

export const createOrderReturnRequest = createAction('CREATE_ORDER_RETURN_REQUEST');
export const createOrderReturnSuccess = createAction('CREATE_ORDER_RETURN_SUCCESS');
export const createOrderReturnFailure = createAction('CREATE_ORDER_RETURN_FAILURE');
export const resetCreateOrderReturn = createAction('RESET_CREATE_ORDER_RETURN');

export const getOrderForWarehouseRequest = createAction('GET_ORDER_FOR_WAREHOUSE_REQUEST');
export const getOrderForWarehouseSuccess = createAction('GET_ORDER_FOR_WAREHOUSE_SUCCESS');
export const getOrderForWarehouseFailure = createAction('GET_ORDER_FOR_WAREHOUSE_FAILURE');

export const resetOrderState = createAction('RESET_ORDER_STATE');
