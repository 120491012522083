const vnLangProduct = {
  'product.helmet': 'Danh sách sản phẩm',
  'product.breadcrumb.title': 'Danh sách sản phẩm',
  'product.breadcrumb.parent': 'Bán hàng',
  'product.modal.create.title': 'Thêm sản phẩm',
  'product.modal.create.confirm.title': 'Xác nhận thêm sản phẩm mới',
  'product.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm sản phẩm này?',
  'product.modal.detail.title': 'Thông tin chi tiết sản phẩm',
  'product.modal.update.title': 'Cập nhật thông tin sản phẩm',
  'product.modal.update.confirm.title': 'Xác nhận cập nhật thông tin sản phẩm',
  'product.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật thông tin sản phẩm này?',
  'product.modal.delete.title': 'Xác nhận xoá sản phẩm',
  'product.modal.delete.description': 'Bạn chắc chắn muốn xoá sản phẩm này?',
  'product.modal.reset-password.title': 'Đặt lại mật khẩu',
  'product.noti.create.success': 'Thêm sản phẩm thành công!',
  'product.noti.create.failure': 'Thêm sản phẩm không thành công! Vui lòng thử lại sau.',
  'product.noti.update.success': 'Cập nhật sản phẩm thành công!',
  'product.noti.update.failure': 'Cập nhật sản phẩm không thành công! Vui lòng thử lại sau.',
  'product.noti.delete.success': 'Xoá sản phẩm thành công!',
  'product.noti.delete.failure': 'Xoá sản phẩm không thành công! Vui lòng thử lại sau.',
  'product.noti.reset-password.success': 'Đặt lại mật khẩu sản phẩm thành công!',
  'product.noti.reset-password.failure': 'Đặt lại mật khẩu sản phẩm không thành công! Vui lòng thử lại sau.',
  'product.btn-create': 'Thêm',
  'product.error-min-length': 'Tối thiểu $x ký tự!',
  'product.error-max-length': 'Tối đa $x ký tự!',
  'product.code.col': 'Mã sản phẩm',
  'product.code.label': 'Mã sản phẩm',
  'product.code.placeholder': 'Nhập mã sản phẩm',
  'product.barcode.col': 'Mã vạch',
  'product.barcode.label': 'Mã vạch',
  'product.barcode.placeholder': 'Nhập mã vạch',
  'product.categories.col': 'Dòng sản phẩm',
  'product.categories.label': 'Dòng sản phẩm',
  'product.categories.placeholder': 'Chọn dòng sản phẩm',
  'product.categories.error.required': 'Vui lòng chọn dòng sản phẩm!',
  'product.name.col': 'Tên sản phẩm',
  'product.name.label': 'Tên sản phẩm',
  'product.name.placeholder': 'Nhập tên sản phẩm',
  'product.name.error.required': 'Vui lòng nhập tên sản phẩm!',
  'product.desc.col': 'Mô tả',
  'product.desc.label': 'Mô tả sản phẩm',
  'product.desc.placeholder': 'Nhập mô tả sản phẩm',
  'product.unit.col': 'Đơn vị',
  'product.unit.label': 'Đơn vị tính cơ bản',
  'product.unit.placeholder': 'Nhập đơn vị tính cơ bản',
  'product.unit.error-required': 'Vui lòng nhập đơn vị tính cơ bản!',
  'product.price.col': 'Giá bán',
  'product.price.label': 'Giá bán (vnđ)',
  'product.price.placeholder': 'Nhập giá bán',
  'product.price.error-required': 'Vui lòng nhập giá bán!',
  'product.price.invalid': 'Nhập giá lớn hơn 0!',
  'product.units.name.label': 'Tên đơn vị',
  'product.units.title.label': 'Tiêu đề đơn vị',
  'product.units.name.placeholder': 'Nhập tên đơn vị',
  'product.units.name.error-required': 'Vui lòng nhập tên đơn vị!',
  'product.units.title.placeholder': 'Nhập tiêu đề đơn vị',
  'product.units.title.error-required': 'Vui lòng nhập tiêu đề đơn vị!',
  'product.units.number.label': 'Giá trị quy đổi',
  'product.units.number.placeholder': 'Nhập giá trị quy đổi',
  'product.units.number.error-required': 'Vui lòng nhập giá trị quy đổi!',
  'product.units.number.error-invalid': 'Giá trị quy đổi phải lớn hơn 0!',
  'product.units.price.label': 'Giá bán (vnđ)',
  'product.units.price.placeholder': 'Nhập giá bán',
  'product.units.price.error-required': 'Vui lòng nhập giá bán!',
  'product.units.code.label': 'Mã sản phẩm',
  'product.units.code.placeholder': 'Nhập mã sản phẩm',
  'product.units.barcode.label': 'Mã vạch',
  'product.units.barcode.placeholder': 'Nhập mã vạch',
  'product.image.col': 'Hình ảnh',
  'product.image.label': 'Tải lên',
  'product.image.label-2': 'Hình ảnh sản phẩm',
  'product.image.error-required': 'Vui lòng tải lên ảnh sản phẩm!',
  'product.btn-add-units-items': 'Thêm đơn vị tính',
  'product.serial.label': 'Sử dụng số serial/imei',
  'product.quantity.label': 'Áp dụng quản lý số lượng kho',
  'product.expiry.label': 'Áp dụng ngày hết hạn',
  'product.cost.label': 'Giá vốn',
  'product.cost.placeholder': 'Nhập giá vốn',
};

export default vnLangProduct;
