// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  // Get All Orders List
  isGetAllOrdersListRequest: false,
  isGetAllOrdersListSuccess: false,
  isGetAllOrdersListFailure: false,
  getAllOrdersListState: {},
  // Create Order Return
  isCreateOrderReturnRequest: false,
  isCreateOrderReturnSuccess: false,
  isCreateOrderReturnFailure: false,
  // Get order for warehouse
  isGetOrderForWarehouseRequest: false,
  isGetOrderForWarehouseSuccess: false,
  isGetOrderForWarehouseFailure: false,
  getOrderForWarehouseState: {},
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Orders List
    [Actions.getAllOrdersListRequest]: (state) => ({
      ...state,
      isGetAllOrdersListRequest: true,
      isGetAllOrdersListSuccess: false,
      isGetAllOrdersListFailure: false,
    }),
    [Actions.getAllOrdersListSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllOrdersListRequest: false,
      isGetAllOrdersListSuccess: true,
      isGetAllOrdersListFailure: false,
      getAllOrdersListState: payload,
    }),
    [Actions.getAllOrdersListFailure]: (state, { payload }) => ({
      ...state,
      isGetAllOrdersListRequest: false,
      isGetAllOrdersListSuccess: false,
      isGetAllOrdersListFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Order Return
    [Actions.createOrderReturnRequest]: (state) => ({
      ...state,
      isCreateOrderReturnRequest: true,
      isCreateOrderReturnSuccess: false,
      isCreateOrderReturnFailure: false,
    }),
    [Actions.createOrderReturnSuccess]: (state, { payload }) => ({
      ...state,
      isCreateOrderReturnRequest: false,
      isCreateOrderReturnSuccess: true,
      isCreateOrderReturnFailure: false,
      getAllOrdersListState: {
        ...state.getAllOrdersListState,
        data: state.getAllOrdersListState.data.map((item) => item.id === payload.id ? ({
          ...item,
          return: payload.items.data.reduce(
            (initial, total) => (total.returnTmp < total.return ? total.return : total.returnTmp) + initial,
            0,
          ),
          items: {
            data: item.items.data.map((item2) => payload.items.data.map((item3) => item3.id).includes(item2.id) ? ({
              ...item2,
              return: payload.items.data.find((item3) => item3.id === item2.id).returnTmp < payload.items.data.find((item3) => item3.id === item2.id).return ? payload.items.data.find((item3) => item3.id === item2.id).return : payload.items.data.find((item3) => item3.id === item2.id).returnTmp,
            }) : item2),
          },
        }) : item),
      },
    }),
    [Actions.createOrderReturnFailure]: (state, { payload }) => ({
      ...state,
      isCreateOrderReturnRequest: false,
      isCreateOrderReturnSuccess: false,
      isCreateOrderReturnFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateOrderReturn]: (state, { payload }) => ({
      ...state,
      isCreateOrderReturnRequest: false,
      isCreateOrderReturnSuccess: false,
      isCreateOrderReturnFailure: false,
      errorMessages: payload,
    }),
    // #endregion

    // #region Get order for warehouse
    [Actions.getOrderForWarehouseRequest]: (state) => ({
      ...state,
      isGetOrderForWarehouseRequest: true,
      isGetOrderForWarehouseSuccess: false,
      isGetOrderForWarehouseFailure: false,
    }),
    [Actions.getOrderForWarehouseSuccess]: (state, { payload }) => ({
      ...state,
      isGetOrderForWarehouseRequest: false,
      isGetOrderForWarehouseSuccess: true,
      isGetOrderForWarehouseFailure: false,
      getOrderForWarehouseState: payload,
    }),
    [Actions.getOrderForWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isGetOrderForWarehouseRequest: false,
      isGetOrderForWarehouseSuccess: false,
      isGetOrderForWarehouseFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region : Local
    [Actions.resetOrderState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
